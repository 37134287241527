import React from 'react';
import styled from 'styled-components';
import { Link as StyledLink } from '@makeship/core';
import { S1, P2 } from '../../../Typography';
import PetitionHelpGuideModal from '../../../Modal/PetitionHelpGuideModal';

const PastPetitionMessageWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(S1)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LearnMore = styled(StyledLink.Primary)``;

const PastPetitionMessage: React.FC = () => {
  const [isHelpGuideModalOpen, setIsHelpGuideOpen] = React.useState(false);

  return (
    <PastPetitionMessageWrapper data-testid="past-petition-signifier">
      <TitleWrapper>
        <Title>⭐️ Made Possible by a Petition!</Title>
      </TitleWrapper>
      <P2>
        Did you support this Petition?{' '}
        <LearnMore onClick={() => setIsHelpGuideOpen(true)}>Learn more about next steps</LearnMore>
      </P2>
      <PetitionHelpGuideModal isOpen={isHelpGuideModalOpen} closeModal={() => setIsHelpGuideOpen(false)} />
    </PastPetitionMessageWrapper>
  );
};

export default PastPetitionMessage;
