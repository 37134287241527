import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Button } from '@makeship/core';
import Link from 'next/link';
import { P2, S1, XSRegularStyles } from '../../../Typography';
import {
  getProductIDFromShopifyGID,
  getProductsDisplayed,
  parseMetafields,
  renderLink,
  renderRedirectText,
} from '../../../../utils/product';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';
import { reorderProducts } from '../../../../utils/petition';

const RedirectBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const ImageWrapper = styled.div<{ hasSingleImage?: boolean }>`
  display: flex;
  gap: 18px;
  width: ${({ hasSingleImage }) => !hasSingleImage && '100%'};
  justify-content: center;
  min-width: 94px;
`;

const StyledImage = styled(Image)`
  border-radius: 0px 16px;
  background-color: ${({ theme }) => theme.colors.cloud};
`;

const ContentWrapper = styled.div<{ hasSingleImage?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ hasSingleImage }) => (hasSingleImage ? 'row-reverse' : 'column')};
  justify-content: ${({ hasSingleImage }) => hasSingleImage && 'flex-end'};
  align-items: ${({ hasSingleImage }) => (hasSingleImage ? 'center' : 'flex-start')};
  gap: 20px;
  margin-bottom: 20px;
`;

const ShopNowButton = styled(Button.Primary)`
  width: 100%;
  padding: 14px 24px;
`;

const CreatorName = styled(P2)`
  ${XSRegularStyles};
  color: ${({ theme }) => theme.colors.neutral7};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 14px;
  }
  width: 94px;
  text-align: center;
`;

const CampaignCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RedirectBlockFloating = styled.div<{ shouldDisplay: boolean; hasSingleImage?: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'block')};
    flex-direction: ${({ shouldDisplay }) => (shouldDisplay ? 'column' : 'unset')};
    transition: max-height 500ms ease;
    overflow: hidden;
    max-height: ${({ shouldDisplay, hasSingleImage }) =>
      shouldDisplay && hasSingleImage ? '226px' : shouldDisplay ? '340px' : '0px'};
    width: 100%;
    position: fixed;
    bottom: -2px;
    right: 0px;
    z-index: 1;
    border: 2px solid ${({ theme }) => theme.colors.neutral1};
    background-color: ${({ theme }) => theme.colors.neutral1};
    border-radius: 12px 12px 0 0;
    box-shadow: 2px -2px 8px 0px rgba(20, 20, 20, 0.2);
  }
`;

const CloseButton = styled(Image)`
  cursor: pointer;
`;

const CloseButtonWrapper = styled.div`
  align-self: flex-end;
`;

type LiveProductRedirectFloatingProps = {
  metafields: Metafields;
  productsBySameCreator: Shopify.ProductEdge[];
  productsInSameMakeshiftCollection: Shopify.ProductEdge[];
  product: Shopify.Product;
  topCampaigns: Shopify.ProductEdge[];
  redirectBlockRef: React.RefObject<HTMLDivElement>;
  makeshiftCollectionTitle?: string | null;
};

const LiveProductRedirectFloating: React.FC<LiveProductRedirectFloatingProps> = ({
  metafields,
  productsBySameCreator,
  productsInSameMakeshiftCollection,
  product,
  topCampaigns,
  redirectBlockRef,
  makeshiftCollectionTitle,
}: LiveProductRedirectFloatingProps) => {
  const { state } = useStore();
  const MAX_PRODUCTS_TO_DISPLAY = 3;
  const productsBySameCreatorToDisplay = reorderProducts(productsBySameCreator).slice(0, MAX_PRODUCTS_TO_DISPLAY);
  const productsInSameMakeshiftCollectionToDisplay = productsInSameMakeshiftCollection.slice(
    0,
    MAX_PRODUCTS_TO_DISPLAY,
  );
  const [isRedirectBlockVisible] = useIntersectionObserver(redirectBlockRef, {
    root: null,
    rootMargin: '-64px 0px 0px 0px', // Set top margin to take into account navigation header
    threshold: 0,
  });
  const [isStickyCTADismissed, setIsStickyCTADismissed] = React.useState(false);
  const handleClose = () => {
    setIsStickyCTADismissed(true);
    posthog.capture('pastCampaign_redirect_floating_dismissed');
  };
  const productsToDisplay = () => {
    if (productsBySameCreatorToDisplay.length > 0) {
      return { products: productsBySameCreatorToDisplay, isDefault: false };
    }
    if (productsInSameMakeshiftCollectionToDisplay.length > 0) {
      return { products: productsInSameMakeshiftCollectionToDisplay, isDefault: false };
    }
    return { products: topCampaigns, isDefault: true };
  };
  const renderProductImages = (productsToDisplay: { products: Shopify.ProductEdge[]; isDefault: boolean }) => {
    if (productsToDisplay.products.length === 1) {
      return productsToDisplay.products.map((product) => (
        <StyledImage
          key={product.node.id}
          src={product.node.images.edges[0].node.transformedSrc}
          alt={product.node.title}
          height={94}
          width={94}
        />
      ));
    }
    if (productsToDisplay.products.length > 0) {
      return productsToDisplay.products.map((productToDisplay, index) => (
        <Link href={`/products/${productToDisplay.node.handle}`} passHref>
          <CampaignCard
            onClick={() =>
              posthog.capture('redirect_product_thumbnail_click', {
                product_title: product.title,
                product_id: getProductIDFromShopifyGID(product.id),
                location: 'past_campaign_redirect_floating',
                is_logged_in: isUserLoggedIn(state.user),
                index,
                productsDisplayed: getProductsDisplayed(
                  productsBySameCreatorToDisplay,
                  productsInSameMakeshiftCollectionToDisplay,
                  topCampaigns,
                ),
                relevant_campaign_exists:
                  productsBySameCreator.length > 0 || productsInSameMakeshiftCollection.length > 0,
              })
            }
          >
            <StyledImage
              key={productToDisplay.node.id}
              src={productToDisplay.node.images.edges[0].node.transformedSrc}
              alt={productToDisplay.node.title}
              height={94}
              width={94}
            />
            <CreatorName>
              {productsToDisplay.isDefault
                ? parseMetafields(productToDisplay.node.metafields).creator
                : productToDisplay.node.title}
            </CreatorName>
          </CampaignCard>
        </Link>
      ));
    }
  };

  return (
    <RedirectBlockFloating
      shouldDisplay={!isRedirectBlockVisible && !isStickyCTADismissed}
      hasSingleImage={
        productsBySameCreatorToDisplay.length === 1 || productsInSameMakeshiftCollectionToDisplay.length === 1
      }
    >
      <RedirectBlockWrapper>
        <CloseButtonWrapper onClick={handleClose}>
          <CloseButton src="/assets/icons/close-icon.svg" width="16" height="16" />
        </CloseButtonWrapper>
        <ContentWrapper
          hasSingleImage={
            productsBySameCreatorToDisplay.length === 1 || productsInSameMakeshiftCollectionToDisplay.length === 1
          }
        >
          <S1>
            {renderRedirectText(
              metafields.creator,
              productsBySameCreatorToDisplay,
              productsInSameMakeshiftCollectionToDisplay,
              makeshiftCollectionTitle,
            )}
          </S1>
          <ImageWrapper
            hasSingleImage={
              productsBySameCreatorToDisplay.length === 1 || productsInSameMakeshiftCollectionToDisplay.length === 1
            }
          >
            {renderProductImages(productsToDisplay())}
          </ImageWrapper>
        </ContentWrapper>
        <Link href={renderLink(productsBySameCreatorToDisplay, productsInSameMakeshiftCollectionToDisplay)} passHref>
          <ShopNowButton
            onClick={() =>
              posthog.capture('pastCampaign_redirect_cta_clicked', {
                productName: product.title,
                productID: getProductIDFromShopifyGID(product.id),
                productsDisplayed: getProductsDisplayed(
                  productsBySameCreatorToDisplay,
                  productsInSameMakeshiftCollectionToDisplay,
                  topCampaigns,
                ),
                is_logged_in: isUserLoggedIn(state.user),
                location: 'past_campaign_redirect_floating',
                relevant_campaign_exists:
                  productsBySameCreator.length > 0 || productsInSameMakeshiftCollection.length > 0,
              })
            }
          >
            Shop Now
          </ShopNowButton>
        </Link>
      </RedirectBlockWrapper>
    </RedirectBlockFloating>
  );
};

export default LiveProductRedirectFloating;
