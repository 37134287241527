import React from 'react';
import styled from 'styled-components';
import { P2 } from '../Typography';
import { ReactComponent as WarningIcon } from '../../../public/assets/icons/warning-icon.svg';

const PreviewWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.warning};
  width: 90%;
  max-width: 736px;
  margin: 8px auto;
  padding: 16px;
  border-radius: 3px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: 100%;
  }
`;

const WarningIconWrapper = styled.div`
  margin-right: 8px;
`;

const PreviewWarning: React.FC = () => (
  <PreviewWarningWrapper>
    <WarningIconWrapper>
      <WarningIcon />
    </WarningIconWrapper>
    <P2>This is a preview of your product page. This page is not live on the Makeship website.</P2>
  </PreviewWarningWrapper>
);

export default PreviewWarning;
