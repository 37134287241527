import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { Button, utils } from '@makeship/core';
import { InView } from 'react-intersection-observer';

import { getBadgesByExternalProductId } from '../../../api/storefront/badge';
import Modal from '..';
import BadgeRow from './badge-row';
import Loader from '../../Loader';
import { H5, S1, Overline } from '../../Typography';

import { useStore } from '../../../store';
import { getStage } from '../../../utils/product';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ProductStage, SortOrder } from '../../../types/common';
import { largeModalTheme } from '../../../styles/themes';
import { decryptShopifyId } from '../../../utils/id';
import config from '../../../../config.json';

const { routes } = config;

const ModalHeader = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100%;
`;

const ModalWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: ${({ height }) => `${height}px`};
  }
`;

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 192px);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-height: none;
    min-height: 0;
    flex: 1 1 0;
  }
`;

const Header = styled(H5)``;

const CancelIcon = styled.div`
  cursor: pointer;
`;

const BadgesHeader = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
`;

const BadgePosition = styled.div`
  margin-left: 16px;
  flex-basis: 20%;
  flex-grow: 0;

  flex-direction: row;
  display: flex;
  align-items: center;
`;

const BadgeSort = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BadgeText = styled(Overline)`
  margin-left: 16px;
  flex-basis: 10%;
  flex-grow: 1;
`;

const BadgeImage = styled.div`
  text-align: center;
  flex-basis: 15%;

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-basis: 20%;
  }
`;

const Badges = styled.div`
  width: 100%;
  overflow-y: scroll;
  margin-top: 64px;
  padding: 0 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 8px;
  }
`;

const ModalFooter = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  box-shadow: 0px 2px 8px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
    align-items: normal;
  }
`;

const Footer = styled(S1)`
  margin: 0 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 16px 0;
  }
`;

type SupportBoardModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  product: Shopify.Product;
};

const SupportBoardModal: React.FC<SupportBoardModalProps> = ({
  isOpen,
  closeModal,
  product,
}: SupportBoardModalProps) => {
  const { state } = useStore();
  const [badges, setBadges] = useState<Badge[]>([]);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>();
  const [reversed, setReversed] = useState<boolean>(false);
  const stage = getStage(product.tags);

  const size = useWindowSize();

  useEffect(() => {
    const sortOrder = stage === ProductStage.Live ? SortOrder.Descending : SortOrder.Ascending;
    setBadges([]);
    setSortOrder(sortOrder);
    setCanLoadMore(true);
  }, [product]);

  const loadMore = () => {
    if (loading) return;

    setLoading(true);

    getBadgesByExternalProductId({
      externalProductId: decryptShopifyId(product.id).toString(),
      direction: sortOrder,
      offset: badges.length.toString(),
      limit: (50).toString(),
    })
      .then(({ data }) => {
        if (!data) return;
        setBadges(badges.concat(data));
        if (data.length < 50) {
          setCanLoadMore(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const changeSortOrder = () => {
    if (sortOrder === SortOrder.Descending) {
      setSortOrder(SortOrder.Ascending);
    } else {
      setSortOrder(SortOrder.Descending);
    }

    setBadges([]);
    setCanLoadMore(true);
  };

  const getBadgesLink = () => {
    if (state.user?.storefrontCustomer?.username) {
      return `${routes.badgeLibrary}/${encodeURIComponent(state.user?.storefrontCustomer?.username || '')}`;
    }

    return routes.signup;
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} theme={largeModalTheme}>
      <ModalWrapper height={size.height}>
        <ModalHeader>
          <Header>Early Supporters</Header>
          <CancelIcon onClick={closeModal}>
            <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
          </CancelIcon>
        </ModalHeader>
        <ModalContent>
          <Badges>
            <BadgesHeader>
              {stage === ProductStage.Past && (
                <BadgePosition>
                  <Overline>Rank</Overline>
                  <BadgeSort onClick={changeSortOrder}>
                    <Image src="/assets/icons/sort-arrows.svg" width={10} height={20} quality={90} />
                  </BadgeSort>
                </BadgePosition>
              )}
              <BadgeText>Username</BadgeText>
              <BadgeImage>
                <Overline>Badge</Overline>
                <BadgeSort onClick={() => setReversed(!reversed)}>
                  <Image src="/assets/icons/reverse.svg" width={16} height={16} quality={90} />
                </BadgeSort>
              </BadgeImage>
            </BadgesHeader>
            {badges.map((badge) => (
              <BadgeRow badge={badge} stage={stage} reversed={reversed} />
            ))}
            {canLoadMore && (
              <InView as="div" onChange={(inView, entry) => inView && canLoadMore && loadMore()}>
                {canLoadMore && <Loader />}
              </InView>
            )}
          </Badges>
        </ModalContent>
        <ModalFooter>
          <Footer>Did you support this Campaign?</Footer>
          <Link href={getBadgesLink()} passHref>
            <Button.Primary>View your Badge</Button.Primary>
          </Link>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};

export default SupportBoardModal;
