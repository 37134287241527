import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { utils } from '@makeship/core';
import { getProductFromContext } from '../../../../context/product';
import { S2 } from '../../../Typography';
import SupportBoard from '../../../SupportBoard';
import HowItWorksToggle from './HowItWorksToggle';
import SpecsToggle from './SpecsToggle';
import { ProductTag } from '../../../../types/common';
import { getEvergreenInventoryState, getProductIDFromShopifyGID, hasProductTag } from '../../../../utils/product';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';

enum InfoDrawerKey {
  Supporters = 0,
  Shipping = 1,
  Details = 2,
}

const InfoDrawerWrapper = styled.div`
  margin: 30px 0;
  padding: 0;

  * {
    max-width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin: 32px -16px;
  }
`;

const InfoDrawerToggle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

const ToggleTitle = styled(S2)<{ isActive?: boolean; itemKey: InfoDrawerKey }>`
  text-align: center;
  margin-bottom: 24px;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  border-bottom: ${({ isActive, theme }) =>
    isActive
      ? `3px solid ${theme.colors.primary}`
      : `3px solid ${utils.hexToRGBA(theme.colors.primaryDarker, theme.alpha.light)}`};
  cursor: pointer;

  ${({ itemKey }) => (itemKey !== InfoDrawerKey.Shipping ? 'width: 85%;' : 'width: 135%;')}
`;

const InfoDrawerContentWrapper = styled.div`
  padding: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: 0 16px;
  }
`;

const Anchor = styled.span`
  display: block;
  visibility: hidden;
`;

const keyTitleArray = ['supporters', 'shipping', 'details'];

type InfoDrawerProps = {
  badges: Badge[];
};

type ToggleItem = {
  key: InfoDrawerKey;
  title: string;
};

const toggleItems: ToggleItem[] = [
  { key: InfoDrawerKey.Supporters, title: 'Supporters' },
  { key: InfoDrawerKey.Shipping, title: 'Shipping & Returns' },
  { key: InfoDrawerKey.Details, title: 'Details' },
];

const InfoDrawer: React.FC<InfoDrawerProps> = ({ badges }: InfoDrawerProps) => {
  const product = getProductFromContext();
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);

  const { state } = useStore();
  const [activeToggleKey, setActiveToggleKey] = useState(
    isEvergreen ? InfoDrawerKey.Shipping : InfoDrawerKey.Supporters,
  );

  let filteredToggleItems = toggleItems;
  if (isEvergreen) {
    filteredToggleItems = filteredToggleItems.filter((item) => item.key !== InfoDrawerKey.Supporters);
  }

  const handleToggleClick = (key: InfoDrawerKey) => {
    if (key !== activeToggleKey) {
      posthog.capture('pdp_infoToggle_click', {
        toggle_title: keyTitleArray[key],
        product_id: getProductIDFromShopifyGID(product.id),
        product_title: product.title,
        evergreen_inventory_state: getEvergreenInventoryState(product),
        is_logged_in: isUserLoggedIn(state.user),
      });
    }
    setActiveToggleKey(key);
  };

  useEffect(() => {
    setActiveToggleKey(isEvergreen ? InfoDrawerKey.Shipping : InfoDrawerKey.Supporters);
  }, [isEvergreen, product]);

  return (
    <>
      <Anchor id="shipping" />
      <InfoDrawerWrapper>
        <InfoDrawerToggle>
          {filteredToggleItems.map((item) => (
            <ToggleTitle
              key={item.key}
              data-testid={`declutter-pdp-${keyTitleArray[item.key]}`}
              isActive={activeToggleKey === item.key}
              onClick={() => handleToggleClick(item.key)}
              itemKey={item.key}
            >
              {item.title}
            </ToggleTitle>
          ))}
        </InfoDrawerToggle>
        <InfoDrawerContentWrapper>
          {!isEvergreen && activeToggleKey === InfoDrawerKey.Supporters && <SupportBoard badges={badges} />}
          {activeToggleKey === InfoDrawerKey.Shipping && <HowItWorksToggle />}
          {activeToggleKey === InfoDrawerKey.Details && <SpecsToggle />}
        </InfoDrawerContentWrapper>
      </InfoDrawerWrapper>
    </>
  );
};

export default InfoDrawer;
