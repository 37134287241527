import React from 'react';
import styled from 'styled-components';

const EmojisColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmojiContainer = styled.div<{ space: number }>`
  position: relative;
  margin-bottom: ${({ space }) => space}px;
`;

const Emoji = styled.span`
  background-color: ${({ theme }) => theme.colors.neutral1};
  padding-top: 4px;
  padding-bottom: 10px;
  font-size: 16px;
`;

const Line = styled.div<{ space: number }>`
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 22px;
  width: 1px;
  height: ${({ space }) => space}px;
  background-color: ${({ theme }) => theme.colors.neutral7};
`;

type EmojiBundle = {
  emoji: string;
  containerSpace: number;
  lineSpace?: number;
};

type InfographicWithEmojisComponentProps = {
  emojis: EmojiBundle[];
};

const InfographicWithEmojisComponent: React.FC<InfographicWithEmojisComponentProps> = ({
  emojis,
}: InfographicWithEmojisComponentProps) => (
  <EmojisColumn>
    {emojis.map(({ emoji, containerSpace, lineSpace }) => (
      <EmojiContainer space={containerSpace}>
        <Emoji>{emoji}</Emoji>
        {lineSpace && <Line space={lineSpace} />}
      </EmojiContainer>
    ))}
  </EmojisColumn>
);

export default InfographicWithEmojisComponent;
