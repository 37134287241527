import { Link as StyledLink } from '@makeship/core';
import styled from 'styled-components';
import React from 'react';
import config from '../../../../../config.json';
import { ProductStage, ProductTag, ProductType } from '../../../../types/common';
import Accordion, {
  AccordionBodyHeading,
  AccordionList,
  AccordionListItem,
  AccordionSectionType,
} from '../../../Accordion';
import Social from '../../../Social';
import { P1, P2, Strong } from '../../../Typography';
import { renderShippingDate } from '../commonFunctions';
import {
  getCustomCampaignTime,
  getProductType,
  getStage,
  hasProductTag,
  parseMetafields,
} from '../../../../utils/product';
import { getProductFromContext } from '../../../../context/product';
import { getPDPTrackingLocation, handleSupportLinkTracking } from '../../../../utils/analytics';

const { routes } = config;

const SocialHeader = styled(P1)`
  margin-top: 16px;
  margin-bottom: 6px;
`;

const DisclaimerText = styled(AccordionBodyHeading)`
  margin-left: 30px;
`;

const ProductSpecsText = styled(P2)``;

const HelpCenterLink = styled(StyledLink.Primary)``;

type ProductDimensions = {
  name: string;
  unit: string;
  default: string;
  key: string;
};

type ProductCareType = {
  careInstructions?: string;
  material: string[];
  dimensions?: ProductDimensions[];
  disclaimer?: string;
  weight?: ProductDimensions[];
};

type ProductCare = {
  [type: string]: ProductCareType;
};

const getProductionTime = (productType: ProductType): string => {
  switch (productType) {
    case ProductType.Plushie:
    case ProductType.Longboi:
    case ProductType.Doughboi:
      return '2-3 months';
    case ProductType.Charm:
      return '45 days';
    case ProductType.VinylFigure:
      return '3-4 months';
    case ProductType.EnamelPin1Pack:
    case ProductType.EnamelPin2Pack:
    case ProductType.EnamelPin3Pack:
      return '50 days';
    default:
      return '2-3 months';
  }
};

export const getProductCare = (metafields: Metafields): ProductCare => ({
  [ProductType.Plushie]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material:
      metafields.promotion === 'gitd'
        ? ['Polypropylene Cotton', 'Plush Fabric (Ultra Soft Fleece)', 'Luminescent Plush Fabric']
        : ['Polypropylene Cotton', 'Plush Fabric (Ultra Soft Fleece)'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '16',
        key: 'height',
      },
    ],
    disclaimer: 'Some heights may vary due to hair, or additional accessories.',
  },
  [ProductType.Longboi]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material: ['Polypropylene Cotton', 'Plush Fabric (Ultra Soft Fleece)'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '11.5',
        key: 'height',
      },
      {
        name: 'Length',
        unit: 'cm',
        default: '50',
        key: 'length',
      },
    ],
    disclaimer: 'Some heights/lengths may vary due to hair, or additional accessories.',
  },
  [ProductType.Sketchbook]: {
    careInstructions: '',
    material: [
      'Sketchbook (60 Pages): 250g Cardboard, 216g Sketch paper',
      'Cover: 350g Cardboard',
      'Artbook (5 pages): 250g Coated Paper, 200g Sketch paper',
      'Label Sleeve: 250g Cardboard',
    ],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '15',
        key: 'height',
      },
      {
        name: 'Length',
        unit: 'cm',
        default: '21',
        key: 'length',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '2',
        key: 'width',
      },
    ],
  },
  [ProductType.Charm]: {
    careInstructions:
      'Remove before exercising or showering, store in pouch, and clean with jewelry polishing cloth to preserve gold plating.',
    material: [
      `18K ${metafields.color === 'gold' ? 'Gold' : 'White Gold'} Plated`,
      'Enamel Graphic',
      'Sterling Silver Base',
    ],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '2',
        key: 'height',
      },
      {
        name: 'Length',
        unit: 'cm',
        default: '2',
        key: 'length',
      },
      {
        name: 'Depth',
        unit: 'mm',
        default: '1',
        key: 'depth',
      },
    ],
  },
  [ProductType.Backpack]: {
    careInstructions: 'Hand wash warm or cool only. Do not machine wash or dry clean.',
    material: [
      'Exterior: Canvas, PU Leather Detailing (Top Handle, Base, Zipper Pull)',
      'Interior: Cotton/Polyester Blend',
      'Adjustable Straps: Canvas and Cotton',
      'Zipper and Buckles: Metal',
      'Graphic: Screen Printing ',
    ],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '43',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '29',
        key: 'length',
      },
    ],
  },
  [ProductType.PlushKeychain]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material: [
      `Interior (main body): Polypropylene Cotton`,
      'Exterior: Plush Fabric (Ultra Soft Fleece)',
      'Band: Silicone',
    ],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '8',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '5',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '5',
        key: 'depth',
      },
    ],
    disclaimer: 'Some heights may vary due to hair, or additional accessories.',
  },
  [ProductType.JumboPlush]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material:
      metafields.promotion === 'gitd'
        ? ['Polypropylene Cotton', 'Plush Fabric (Ultra Soft Fleece)', 'Luminescent Plush Fabric']
        : ['Polypropylene Cotton', 'Plush Fabric (Ultra Soft Fleece)'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '24',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '24',
        key: 'width',
      },
    ],
    disclaimer: 'Some heights may vary due to hair, or additional accessories.',
  },
  [ProductType.SquishyPlush]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material: [
      'Interior (main body): Memory Foam',
      'Interior (external structures): PP Cotton Stuffing ',
      'Exterior: Stretchy Plush Fabric and Embroidery',
    ],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '19',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '19.5',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '16',
        key: 'depth',
      },
    ],
    disclaimer: 'Some heights may vary due to hair, or additional accessories.',
  },
  [ProductType.VinylFigure]: {
    careInstructions:
      'To keep your figure in optimal condition limit exposure to these five elements: dust, oil, sunlight, heat, moisture. Long-term exposure to these elements can lead to eventual fading or weakening of Vinyl material.  Figures can be cleaned using either a microfiber or cotton cloth that is dry or lightly damp. If using water be sure to pat (not rub) the figure dry after cleaning. If additional care is needed, please use a Q-tip or cloth (microfiber or cotton) along with household dish soap and warm water to gently spot clean any affected areas.',
    material: ['Coloured PVC', 'Oil Paint and Water-Based Paint', 'Varnish'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '10',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '6',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '5',
        key: 'depth',
      },
    ],
    disclaimer: 'Figure dimensions will vary depending on specific character and pose.',
  },
  [ProductType.FannyPack]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material: ['Exterior: Nylon Polyester', 'Interior: Cotton', 'Zipper: Metal'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '15',
        key: 'height',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '23',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '7.5',
        key: 'depth',
      },
    ],
  },
  [ProductType.EnamelPin1Pack]: {
    material: ['Plated Zinc Alloy'],
    dimensions: [
      {
        name: 'Length',
        unit: 'cm',
        default: '3.8',
        key: 'length',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '3.8',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '0.2',
        key: 'depth',
      },
    ],
    disclaimer: 'Some lengths and/or widths may vary based on specific character pose.',
    weight: [
      {
        name: 'Weight',
        unit: 'grams',
        default: '11.4',
        key: 'weight',
      },
    ],
  },
  [ProductType.EnamelPin2Pack]: {
    material: ['Plated Zinc Alloy'],
    dimensions: [
      {
        name: 'Length',
        unit: 'cm',
        default: '3.8',
        key: 'length',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '3.8',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '0.2',
        key: 'depth',
      },
    ],
    disclaimer: 'Some lengths and/or widths may vary based on specific character pose.',
    weight: [
      {
        name: 'Weight',
        unit: 'grams',
        default: '11.4',
        key: 'weight',
      },
    ],
  },
  [ProductType.EnamelPin3Pack]: {
    material: ['Plated Zinc Alloy'],
    dimensions: [
      {
        name: 'Length',
        unit: 'cm',
        default: '3.8',
        key: 'length',
      },
      {
        name: 'Width',
        unit: 'cm',
        default: '3.8',
        key: 'width',
      },
      {
        name: 'Depth',
        unit: 'cm',
        default: '0.2',
        key: 'depth',
      },
    ],
    disclaimer: 'Some lengths and/or widths may vary based on specific character pose.',
    weight: [
      {
        name: 'Weight',
        unit: 'grams',
        default: '11.4',
        key: 'weight',
      },
    ],
  },
  [ProductType.Doughboi]: {
    careInstructions: 'Handwash warm or cool only. Do not machine wash or dry clean.',
    material: ['Spandex Plush Fabric', '3D Cotton Down Filling'],
    dimensions: [
      {
        name: 'Height',
        unit: 'cm',
        default: '16.0',
        key: 'height',
      },
    ],
    disclaimer: 'Note: Some heights may vary due to additional details.',
  },
});

export const getProductWarning = (productType: ProductType): string => {
  switch (productType) {
    case ProductType.Plushie:
    case ProductType.Longboi:
    case ProductType.Doughboi:
    case ProductType.VinylFigure:
      return 'WARNING: CHOKING HAZARD. Small parts. Not for children under 3 years of age. Adult collectible. Not a toy. Recommended for ages 15 and up.';
    case ProductType.EnamelPin1Pack:
    case ProductType.EnamelPin2Pack:
    case ProductType.EnamelPin3Pack:
      return 'WARNING: CHOKING HAZARD. Small parts and sharp edges. Not for children under 3 years of age. Adult collectible. Not a toy. Recommended for ages 15 and up.';
    default:
      return '';
  }
};

const getAccordionData = (
  metafields: Metafields,
  title: string,
  productType: ProductType,
  isLimited: boolean,
  isEvergreen: boolean,
  stage: string,
  product: Shopify.Product,
  customCampaignTime?: string,
): AccordionSectionType[] => {
  const defaultAccordionData: AccordionSectionType[] = [
    ...(metafields.promotion === 'dwob'
      ? [
          {
            title: 'Plushies for Charity',
            body: [
              {
                title: 'Charity',
                bodyText: 'Doctors Without Borders/Médecins Sans Frontières',
              },
              {
                title: 'Their Mission',
                bodyText:
                  'In more than 70 countries around the world, Doctors Without Borders/Médecins Sans Frontières (MSF) provides emergency medical care to people affected by conflict, outbreaks of disease, disaster and lack of access to healthcare. ',
              },
              {
                title: 'How your Support Helps',
                bodyText:
                  'Your support helps enable our teams to work with communities to provide humanitarian assistance, strengthen health systems and continue to provide lifesaving emergency medical care. ',
              },
            ],
          },
        ]
      : []),
    ...(metafields.charity
      ? [
          {
            title: 'Plushies for Charity',
            body: [
              {
                title: 'Charity Name',
                bodyText: metafields.charity,
              },
              {
                title: 'Our Mission',
                bodyText: `Join Makeship and our Creators as we celebrate inclusion and the LGBTIQ2S+ community. Support your favorite creator and a great cause as a portion of select campaign proceeds will be donated to a charity of each creator's choice!`,
              },
            ],
          },
        ]
      : []),
  ];

  const howCampaignsWork: AccordionSectionType = {
    title: 'How do Campaigns Work?',
    body: [
      {
        title: 'Design',
        bodyText: 'We collaborate with each Creator to design a unique product for their Fans.',
      },
      {
        title: 'Launch',
        bodyText: `${
          isLimited
            ? 'Each product is available until all units are sold.'
            : `Each product Campaign launches for ${customCampaignTime || '21'} days to reach the minimum funding goal.`
        }`,
      },
      {
        title: 'Make',
        bodyText: `Our team then makes each limited edition product batch.`,
      },
      {
        title: 'Ship',
        bodyText: 'Once the product is ready, we ship it directly to you.',
      },
    ],
  };

  const shippingAndReturns: AccordionSectionType = {
    title: 'Shipping and Returns',
    subtitle:
      metafields.delivery && !isLimited
        ? renderShippingDate(metafields.delivery, ProductStage.Past, false, product)
        : '',
    body: [
      {
        title: 'Shipping',
        body: (
          <>
            {isLimited || isEvergreen ? (
              <ProductSpecsText>Each order will be shipped 2-4 days after purchase.</ProductSpecsText>
            ) : (
              <ProductSpecsText>
                Each {title} will be shipped {getProductionTime(productType)} after the Campaign end date.
              </ProductSpecsText>
            )}
          </>
        ),
      },
      {
        title: 'Delivery',
        bodyText:
          'Once shipped, estimated delivery is 7-15 business days. *Delivery to some areas may take 10-30 business days.',
      },
      {
        title: 'Returns and Refunds',
        body: (
          <ProductSpecsText>
            All sales are final and non-refundable.
            {!isLimited && !isEvergreen
              ? ' If a Campaign does not achieve its funding goal, all orders will be refunded within 2-7 business days. '
              : ' '}
            For damaged products, please visit{' '}
            <HelpCenterLink target="_blank" href={routes.helpCenter} rel="noopener noreferrer">
              Makeship Help Center.
            </HelpCenterLink>
          </ProductSpecsText>
        ),
      },
    ],
  };

  const returns: AccordionSectionType = {
    title: 'Returns',
    body: [
      {
        title: 'Returns',
        body: (
          <ProductSpecsText>
            Unfortunately, this campaign did not reach its minimum funding goal. All orders will be refunded within 2-7
            business days.
          </ProductSpecsText>
        ),
      },
    ],
  };

  const productDetails: AccordionSectionType = {
    title: 'Product Details',
    body: [
      productType === ProductType.EnamelPin1Pack ||
      productType === ProductType.EnamelPin2Pack ||
      productType === ProductType.EnamelPin3Pack
        ? {
            body: (
              <ProductSpecsText>
                Orders contain{' '}
                {productType === ProductType.EnamelPin1Pack ? 1 : productType === ProductType.EnamelPin2Pack ? 2 : 3}{' '}
                hard enamel pin designs. Drawstring bag not included.
              </ProductSpecsText>
            ),
          }
        : {},
      getProductCare(metafields)[productType]?.dimensions
        ? {
            title: 'Dimensions',
            body: (
              <>
                <AccordionList>
                  {getProductCare(metafields)[productType]?.dimensions?.map((dimension) => (
                    <AccordionListItem key={dimension.name}>
                      <ProductSpecsText>
                        {dimension.name}: {metafields[dimension.key as keyof typeof metafields] || dimension.default}
                        {dimension.unit}
                      </ProductSpecsText>
                    </AccordionListItem>
                  ))}
                </AccordionList>
                {getProductCare(metafields)[productType]?.disclaimer && (
                  <DisclaimerText>{getProductCare(metafields)[productType]?.disclaimer}</DisclaimerText>
                )}
              </>
            ),
          }
        : {},
      {
        title: 'Materials',
        body: (
          <AccordionList>
            {getProductCare(metafields)[productType]?.material.map((material) => (
              <AccordionListItem key={material}>
                <ProductSpecsText>{material}</ProductSpecsText>
              </AccordionListItem>
            ))}
          </AccordionList>
        ),
      },
      {
        title: 'Care Instructions',
        bodyText: getProductCare(metafields)[productType]?.careInstructions,
      },
      {
        title: 'Warning',
        bodyText: getProductWarning(productType),
      },
    ],
  };

  const hoodieProductDetails: AccordionSectionType = {
    title: 'Product Details',
    body: [
      {
        title: 'Materials',
        body: (
          <AccordionList>
            <ProductSpecsText>55% cotton, 45% polyester.*</ProductSpecsText>{' '}
            <ProductSpecsText>*Exclusive of decorative details.</ProductSpecsText>
            <ProductSpecsText>
              <Strong>Our premium poly-cotton blend offers a warm, low maintenance and more durable Hoodie.</Strong>
            </ProductSpecsText>
          </AccordionList>
        ),
      },
      {
        title: 'Care Instructions',
        body: (
          <AccordionList>
            <ProductSpecsText>Machine washable. Please follow care instructions on Hoodie label.</ProductSpecsText>
            <br />
            <ProductSpecsText>
              Please contact{' '}
              <StyledLink.Primary
                href="https://support.makeship.com/hc/en-us/requests/new"
                onClick={() => {
                  handleSupportLinkTracking('open_support_ticket', getPDPTrackingLocation(product), product);
                }}
              >
                Makeship Customer Support
              </StyledLink.Primary>{' '}
              with any questions or if you need assistance.
            </ProductSpecsText>
          </AccordionList>
        ),
      },
    ],
  };

  if (getProductCare(metafields)[productType]?.weight) {
    productDetails.body.push({
      title: 'Weight',
      body: (
        <>
          <AccordionList>
            {getProductCare(metafields)[productType]?.weight?.map((weight) => (
              <AccordionListItem key={weight.name}>
                <ProductSpecsText>
                  Approximately {metafields[weight.key as keyof typeof metafields] || weight.default} {weight.unit}
                </ProductSpecsText>
              </AccordionListItem>
            ))}
          </AccordionList>
        </>
      ),
    });
  }

  if (stage !== ProductStage.Failed) {
    if (productType === ProductType.Hoodie) {
      defaultAccordionData.push(hoodieProductDetails);
    } else {
      defaultAccordionData.push(productDetails);
    }
  }

  defaultAccordionData.push(stage === ProductStage.Failed ? returns : shippingAndReturns);
  if (!isLimited && !isEvergreen) {
    defaultAccordionData.push(howCampaignsWork);
  }

  return defaultAccordionData;
};

const ProductAccordion: React.FC = () => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const productType = getProductType(product.productType);
  const stage = getStage(product.tags);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const customCampaignTime = getCustomCampaignTime(product.tags);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);

  return (
    <>
      <Accordion
        data={getAccordionData(
          metafields,
          product.title,
          productType || ProductType.Plushie,
          isLimited,
          isEvergreen,
          stage,
          product,
          customCampaignTime,
        )}
      />
      <SocialHeader>Share this {isEvergreen ? 'Product' : 'Campaign'}</SocialHeader>
      <Social handle={product.handle} />
    </>
  );
};

export default ProductAccordion;
