import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Caption } from '../Typography';

const defaultTop = 120;
const defaultLeft = 0;
const defaultDuration = 8;

const BarWrapper = styled.div<{ top: number; left: number; hide: boolean; fixed: boolean }>`
  display: none;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  left: 0;
  top: ${({ theme, top }) => theme.bannerHeight + top}px;
  padding: 4px 16px;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 0 3px 3px 0;
  transform: translate(${({ hide }) => (hide ? '-110%' : '0')});
  transition-property: transform;
  transition-duration: 1s;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BarText = styled(Caption)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral1};
`;

type NotificationBarProps = {
  start: boolean;
  content: string | JSX.Element;
  top?: number;
  left?: number;
  duration?: number;
  fixed?: boolean;
};

const NotificationBar: React.FC<NotificationBarProps> = ({
  start,
  content,
  top,
  left,
  duration = defaultDuration,
  fixed = false,
}: NotificationBarProps) => {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (start) {
      setTimeout(() => {
        setHide(false);
      }, 1000);

      setTimeout(() => {
        setHide(true);
      }, duration * 1000);
    }
  }, [start]);

  return start ? (
    <BarWrapper top={top || defaultTop} left={left || defaultLeft} hide={hide} fixed={fixed}>
      {typeof content === 'string' ? <BarText>{content}</BarText> : content}
    </BarWrapper>
  ) : (
    <></>
  );
};

export default NotificationBar;
