import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';

import Modal from '..';
import { H5, P2, Caption, Overline, S1, Strong } from '../../Typography';
import { modalTheme } from '../../../styles/themes';

const ModalContent = styled.div`
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  position: relative;
`;

const ModalCaption = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 32px;
  top: 26px;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  text-align: left;
  margin: 16px 0;
`;

const GiftingInfographicWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const InfographicWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 8px 16px 0 0;
`;

const GiftingInfographicDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const GiftingMoreDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`;

const GiftingInfographicItem = styled.div<{ marginBottom?: string; height?: string | null }>`
  ${({ height }) => height && `height: ${height}px;`}
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-top: -24px;
  }
  & > ${Overline} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const giftingModalTheme = {
  ...modalTheme,
  content: {
    ...modalTheme.content,
    borderRadius: '50px',
  },
};

type GiftingModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const GiftingModal: React.FC<GiftingModalProps> = ({ isOpen, closeModal }: GiftingModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal} theme={giftingModalTheme}>
    <ModalContent>
      <CancelIcon onClick={closeModal}>
        <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
      </CancelIcon>
      <ModalHeader>
        <H5>How Does Gifting Work?</H5>
      </ModalHeader>
      <ModalCaption>Purchasing a Plushie to Gift</ModalCaption>
      <GiftingInfographicWrapper>
        <InfographicWrapper>
          <Image
            src="/assets/gifting/gifting-modal-infographic-mobile.png"
            alt="Infographic Icon"
            width={25}
            height={182.14}
            layout="fixed"
          />
        </InfographicWrapper>
        <GiftingInfographicDetails>
          <GiftingInfographicItem marginBottom="22" height="74">
            <S1>Order Gifted Plushie</S1>
            <P2>Provide your details in checkout to receive a gift code after purchase via email.</P2>
          </GiftingInfographicItem>
          <GiftingInfographicItem marginBottom="16" height="70">
            <S1>Get Gift Code via Email</S1>
          </GiftingInfographicItem>
          <GiftingInfographicItem marginBottom="18">
            <S1>Share Code With a Friend</S1>
          </GiftingInfographicItem>
        </GiftingInfographicDetails>
      </GiftingInfographicWrapper>
      <GiftingMoreDetailSection>
        <div>
          <ModalCaption>Redeeming Gifted Plushies</ModalCaption>
          <P2>
            The recipient of your gift must enter the code in checkout to redeem your gift. Once the product is ready,
            it will be shipped to the gifted recipient.
          </P2>
        </div>
        <div>
          <ModalCaption>Gifted Plushies Cost Breakdown</ModalCaption>
          <P2>
            To ensure the gift is <Strong>100% free</Strong> for the receiver, the price is preset to include the retail
            price, shipping, and taxes.
          </P2>
        </div>
      </GiftingMoreDetailSection>
    </ModalContent>
  </Modal>
);

export default GiftingModal;
