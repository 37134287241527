import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Button, utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import { CartEventLocations, ProductStage } from '../../../../types/common';
import { Caption, P2, S2, Strong } from '../../../Typography';
import { useStore } from '../../../../store';
import { addProduct, showCart } from '../../../../store/store.actions';
import config from '../../../../../config.json';
import { analyticsTrackAddToCart, analyticsTrackViewCart } from '../../../../utils/analytics';
import { getProductIDFromShopifyGID, parseMetafields } from '../../../../utils/product';
import { getProductFromContext } from '../../../../context/product';
import GiftingModal from '../../../Modal/GiftingModal';
import Stepper from '../../../Stepper';

const { elements } = config;

const GiftingContainer = styled.div`
  margin: 64px 0 32px 0;
`;

const ProductContainer = styled.div`
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0;
  justify-content: space-between;
  width: 100%;
`;

const GiftDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const GiftDetailText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

const ModalLink = styled(StyledLink.Primary)``;

const AddToCartButton = styled(Button.Primary)<{ uiDisabled: boolean }>`
  width: 100%;
`;

const StyledCaption = styled(Caption)`
  margin-bottom: 4px;
`;

const GiftingTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 6px;
`;

const GiftingTitleText = styled(S2)``;

const GiftingSubtext = styled(P2)``;

type GiftingComponentProps = { stage: ProductStage; giftProduct: Shopify.Product };

const GiftingComponent: React.FC<GiftingComponentProps> = ({ stage, giftProduct }: GiftingComponentProps) => {
  const giftingCollectionRef = useRef<HTMLDivElement>(null);
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const { state, dispatch } = useStore();
  const [number, setNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const handleAddToCart = () => {
    addProduct(state.cart, giftProduct, number, giftProduct.variants.edges[0].node).then((action) => dispatch(action));
    dispatch(showCart());
    analyticsTrackAddToCart(giftProduct, number || 0, state.user, CartEventLocations.GiftButton);
    analyticsTrackViewCart(
      state.cart.checkout?.lineItems.edges,
      state.cart.checkout?.totalPriceV2.amount,
      state.user,
      CartEventLocations.CartPopout,
    );
  };

  const incrementNumber = () => {
    setNumber((number || 0) + 1);
  };

  const decrementNumber = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handleLearnMore = () => {
    setIsOpen(true);
    posthog.capture('open_gifting_modal', {
      product_name: product.title,
      shopify_product_id: getProductIDFromShopifyGID(product.id),
    });
  };

  return (
    <div ref={giftingCollectionRef}>
      <GiftingContainer>
        <GiftingTitle>
          <Image src="/assets/gift_icon.svg" alt="Gift Icon" width={20} height={20} layout="fixed" />
          <GiftingTitleText>Gift a Plushie to a Friend!</GiftingTitleText>
        </GiftingTitle>
        <GiftingSubtext>
          Support {metafields.creator} and gift a {product.title} to a friend.{` `}
          <Strong>You will receive a gift code via email to share</Strong> after purchase.{` `}
          <ModalLink onClick={() => handleLearnMore()}>Learn more about gifting.</ModalLink>
        </GiftingSubtext>
        <ProductContainer>
          <DetailRow>
            <GiftDetailWrapper>
              <Image
                src={giftProduct.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                alt={giftProduct.images.edges[0]?.node.altText || 'product image'}
                width={100}
                height={100}
                quality={90}
                unoptimized
                data-testid="product-card-image"
              />
              <GiftDetailText>
                <GiftingTitleText>Gift a {product.title}</GiftingTitleText>
                <GiftingSubtext>${giftProduct.variants.edges[0].node.priceV2.amount}</GiftingSubtext>
                <StyledCaption>Inclusive of tax + shipping</StyledCaption>
              </GiftDetailText>
            </GiftDetailWrapper>
            <Stepper value={number} increase={incrementNumber} decrease={decrementNumber} />
          </DetailRow>
        </ProductContainer>
        <AddToCartButton
          id={elements.addToCartButton}
          onClick={() => handleAddToCart()}
          uiDisabled={stage === ProductStage.Draft}
          disabled={stage === ProductStage.Draft}
        >
          Gift a Friend
        </AddToCartButton>
      </GiftingContainer>
      <GiftingModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </div>
  );
};

export default GiftingComponent;
