import { ProductType } from '../../../../types/common';

type VariantIntro = {
  [productType: string]: {
    title: string;
    description: string;
  };
};

export const variantIntro = (color?: string): VariantIntro => ({
  [ProductType.Plushie]: {
    title: 'Add the Digital Production Design',
    description:
      'Support your favorite Creator and add a copy of the final production design of this Plush to your purchase! For personal use only.',
  },
  [ProductType.Longboi]: {
    title: 'Add the Digital Production Design',
    description:
      'Support your favorite Creator and add a copy of the final production design of this Longboi to your purchase! For personal use only.',
  },
  [ProductType.Charm]: {
    title: 'Add a Bracelet',
    description: `Add an adjustable 18K ${color === 'gold' ? 'Gold' : 'White Gold'} Plated bracelet to show off all your
    charms.`,
  },
  [ProductType.FannyPack]: {
    title: 'Add the Digital Production Design',
    description:
      'Support your favorite Creator and add a copy of the final production design of this Fanny Pack to your purchase! For personal use only.',
  },
  [ProductType.PlushKeychain]: {
    title: 'Add the Digital Production Design',
    description:
      'Support your favorite Creator and add a copy of the final production design of this Plush Keychain to your purchase! For personal use only.',
  },
});

type VariantDescription = {
  [productType: string]: {
    [index: number]: JSX.Element | string;
  };
};

export const variantDescription: VariantDescription = {
  [ProductType.Plushie]: { 0: '', 1: 'Download link emailed to you once a successful campaign ends' },
  [ProductType.Longboi]: { 0: '', 1: 'Download link emailed to you once a successful campaign ends' },
  [ProductType.FannyPack]: { 0: '', 1: 'Download link emailed to you once a successful campaign ends' },
  [ProductType.PlushKeychain]: { 0: '', 1: 'Download link emailed to you once a successful campaign ends' },
  [ProductType.JumboPlush]: { 0: '', 1: 'Download link emailed to you once a successful campaign ends' },
  [ProductType.Charm]: {
    0: 'Accessories not included',
    1: `Fits wrist sizes 14 - 19 CM / 5.5" - 7.5"`,
  },
};
