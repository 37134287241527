import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { Link as StyledLink, utils } from '@makeship/core';
import posthog from 'posthog-js';
import { H5, S1, Caption } from '../../Typography';
import { ProductStage } from '../../../types/common';

import config from '../../../../config.json';

const { routes } = config;

const BadgeRowWrapper = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};

  &:last-of-type {
    border-bottom: 0;
  }
`;

const BadgePosition = styled.div`
  margin-left: 16px;
  flex-basis: 20%;
  flex-grow: 0;
  display: flex;
  align-items: center;
`;

const BadgeText = styled(S1)`
  margin-left: 16px;
  flex-basis: 10%;
  flex-grow: 1;
`;

const BadgeImage = styled.div`
  margin: 4px auto;
  text-align: center;
  flex-basis: 15%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-basis: 20%;
  }
`;

const tier: { [key: number]: string } = {
  1: 'Diamond Badge',
  2: 'Gold Badge',
  3: 'Silver Badge',
};

type BadgeRowProps = {
  badge: Badge;
  stage: ProductStage;
  reversed: boolean;
};

const BadgeRow: React.FC<BadgeRowProps> = ({ badge, stage, reversed }: BadgeRowProps) => (
  <BadgeRowWrapper>
    {stage === ProductStage.Past && (
      <BadgePosition>
        <H5>{badge.position}</H5>
      </BadgePosition>
    )}
    <BadgeText>
      {badge.customer && badge.customer.username !== 'Anonymous' ? (
        <Link
          onClick={() =>
            posthog.capture('pdp_badges_username_click', {
              customerID: badge.customer?.id,
              badgeID: badge.id,
              productID: badge.productId,
              productStage: stage,
            })
          }
          href={`${routes.badgeLibrary}/${encodeURIComponent(badge.customer?.username || '')}`}
          passHref
        >
          <StyledLink.Primary>{badge.customer?.username}</StyledLink.Primary>
        </Link>
      ) : (
        <>{badge.customer?.username}</>
      )}
      {stage === ProductStage.Past ? (
        <Caption>Awarded {tier[badge.tier]}</Caption>
      ) : (
        <Caption>Supported {utils.getTimeFrom(badge.createdOn)} ago</Caption>
      )}
    </BadgeText>
    <BadgeImage>
      <Link href={`${routes.badges}/${badge.badgeId}`} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() =>
            posthog.capture('pdp_badges_singleBadge_click', {
              customerID: badge.customer?.id,
              badgeID: badge.id,
              productID: badge.productId,
              productStage: stage,
            })
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={!reversed ? badge.badgeFrontUrl : badge.badgeBackUrl} width={52} height={52} unoptimized />
        </a>
      </Link>
    </BadgeImage>
  </BadgeRowWrapper>
);

export default BadgeRow;
