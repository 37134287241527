import React from 'react';
import styled from 'styled-components';
import { getProductFromContext } from '../../../../context/product';
import { getStage, parseMetafields } from '../../../../utils/product';
import { Caption } from '../../../Typography';
import { transformHtml } from '../../../../utils/htmr';
import { ProductStage } from '../../../../types/common';

const PromoBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 16px;
  padding-bottom: 16px;
`;

const PromoBannerLogo = styled.div`
  display: flex;
  max-width: 150px;
  max-height: 150px;
`;

const PromoBannerImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
`;

const PromoBanner: React.FC = () => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);

  const shouldDisplayMessageUnderProgressBar = (
    product: Shopify.Product,
    stage: ProductStage,
    metafields: Metafields,
  ): boolean =>
    (stage === ProductStage.Live || stage === ProductStage.Draft) &&
    metafields.promotionalCaption !== undefined &&
    metafields.promotionalImage !== undefined;

  const parsePromoImage = (raw: string): string =>
    raw.substring(raw.indexOf('src="') + 5, raw.lastIndexOf('"></figure>'));

  return shouldDisplayMessageUnderProgressBar(product, stage, metafields) ? (
    <>
      <PromoBannerWrapper>
        <PromoBannerLogo>
          <PromoBannerImage
            src={metafields.promotionalImage && parsePromoImage(metafields.promotionalImage)}
            alt={metafields.brand}
          />
        </PromoBannerLogo>
        <Caption>{metafields.promotionalCaption && transformHtml(metafields.promotionalCaption)}</Caption>
      </PromoBannerWrapper>
    </>
  ) : (
    <></>
  );
};

export default PromoBanner;
