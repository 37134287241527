import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { getProductFromContext } from '../../context/product';
import { ProductStage, ProductTag } from '../../types/common';
import { getStage, hasProductTag, parseMetafields } from '../../utils/product';
import ImageGallery from '../ImageGallery';
import Carousel from '../Carousel';
import Product360Viewer from '../pages/products/Product360Viewer';

const ProductCarouselWrapper = styled.div`
  flex: 1 1;
  padding-right: 40px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    padding-right: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding-right: 0;
  }
`;

const BrandOverlay = styled.div`
  position: absolute;
  top: 0;
  margin-top: 70%;
  right: 0;
  width: 90px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-top: 75%;
  }
`;

const BrandOverlayDWOB = styled.div`
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(239, 239, 239, 0.8);
  border-radius: 8px;
  padding: 8px;
`;

const ImageGalleryWrapper = styled.div`
  display: block;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 112px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const ImageCarouselWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: contain;

  border-radius: 0px 8px 8px 8px;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.cloud, theme.alpha.light)};
`;

const StyledImageBorder = styled.div`
  display: flex;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.midnight, theme.alpha.extraLight)};
`;

// Mayhem 2024
const BrandOverlayMayhem = styled.div`
  position: absolute;
  top: 0;
  margin-top: 67%;
  right: 4%;
  width: 80px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-top: 87%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 82%;
  }
`;

type ProductPageCarouselProps = {
  productImages: Shopify.ImageEdge[];
  product360Images: Shopify.ImageEdge[] | null;
  currentHas360View: boolean;
};

const ProductPageCarousel: React.FC<ProductPageCarouselProps> = ({
  productImages,
  product360Images,
  currentHas360View,
}: ProductPageCarouselProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);
  const metafields = parseMetafields(product.metafields);
  const renderCarouselCards = (
    productImages: Shopify.ImageEdge[],
    product360Images: Shopify.ImageEdge[] | null,
  ): JSX.Element[] => {
    let elements: JSX.Element[] = productImages.map((edge, index) => (
      <StyledImageBorder>
        <StyledImage
          key={edge.node.transformedSrc}
          data-testid="mobile-image"
          src={edge.node.transformedSrc}
          alt={edge.node.altText || 'product image'}
          width={1000}
          height={1000}
          quality={90}
          priority={index === 0}
          unoptimized
        />
      </StyledImageBorder>
    ));

    if (product360Images && product360Images.length > 0) {
      elements = [
        <div data-testid="product-360-viewer-mobile">
          <Product360Viewer imageArray={product360Images?.map((edge): Shopify.Image => edge.node)} />
        </div>,
      ].concat(elements);
    }

    return elements;
  };

  const renderBrandOverlay = (metafields: Metafields) => {
    if (metafields.promotion === 'dwob')
      return (
        <BrandOverlayDWOB>
          <Image src="/assets/dwob-logo.png" width={110} height={45} layout="fixed" unoptimized />
        </BrandOverlayDWOB>
      );
    if (hasProductTag(product.tags, ProductTag.Mayhem) && metafields.brand) {
      return (
        <BrandOverlayMayhem>
          <Image src={metafields.brand} width={80} height={80} unoptimized />
        </BrandOverlayMayhem>
      );
    }
    if (metafields.brand && metafields.promotion !== 'dwob') {
      return (
        <BrandOverlay>
          <Image src={metafields.brand} width={160} height={160} unoptimized />
        </BrandOverlay>
      );
    }
  };

  return (
    <ProductCarouselWrapper>
      <ImageGalleryWrapper>
        <ImageGallery
          images={productImages.map((edge): Shopify.Image => edge.node)}
          images360={product360Images?.map((edge): Shopify.Image => edge.node) || []}
          has360View={currentHas360View}
          product={product}
          isComingSoon={stage === ProductStage.ComingSoon}
        />
        {renderBrandOverlay(metafields)}
      </ImageGalleryWrapper>
      <ImageCarouselWrapper>
        <Carousel cardNumber={1} product={product} isComingSoon={stage === ProductStage.ComingSoon}>
          {productImages.length > 0 || (product360Images && product360Images.length > 0)
            ? renderCarouselCards(productImages, product360Images)
            : [
                <StyledImageBorder>
                  <StyledImage
                    key="/assets/default_product_asset.png"
                    src="/assets/default_product_asset.png"
                    alt="product image"
                    width={1000}
                    height={1000}
                    quality={90}
                  />
                </StyledImageBorder>,
              ]}
        </Carousel>
        {renderBrandOverlay(metafields)}
      </ImageCarouselWrapper>
    </ProductCarouselWrapper>
  );
};

export default ProductPageCarousel;
