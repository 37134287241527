import React from 'react';
import styled from 'styled-components';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import { S2 } from '../../../Typography';
import { getEvergreenInventoryState } from '../../../../utils/product';
import { EvergreenInventoryState } from '../../../../types/common';

const EvergreenBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

const EvergreenText = styled(S2)`
  color: #d6008b;
`;

const EvergreenBanner: React.FC = () => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { totalInventory } = productDetails;
  const evergreenState = getEvergreenInventoryState(product);

  if (evergreenState === EvergreenInventoryState.LowStock) {
    return (
      <EvergreenBannerWrapper>
        <EvergreenText data-testid="evergreen-alert-text">Only {totalInventory} left! Order soon 🚨</EvergreenText>
      </EvergreenBannerWrapper>
    );
  }
  return <></>;
};

export default EvergreenBanner;
