import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Link as StyledLink, utils } from '@makeship/core';
import { S1, S1Styles, Caption } from '../Typography';

import config from '../../../config.json';

const { routes } = config;

const BadgeRowWrapper = styled.div`
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  }
`;

const BadgeText = styled.div`
  ${S1Styles}
  margin-left: 16px;
`;

const BadgeImage = styled.div`
  margin: 4px 16px 4px 4px;
`;

const BadgeSupportedCaption = styled(Caption)``;

const SupporterLink = styled(StyledLink.Primary)``;

const SupporterText = styled(S1)``;

type BadgeRowProps = {
  badge: Badge;
};

const BadgeRow: React.FC<BadgeRowProps> = ({ badge }: BadgeRowProps) => (
  <BadgeRowWrapper>
    <BadgeText>
      {badge.customer && badge.customer.username !== 'Anonymous' ? (
        <Link href={`${routes.badgeLibrary}/${encodeURIComponent(badge.customer?.username || '')}`} passHref>
          <SupporterLink>{badge.customer?.username}</SupporterLink>
        </Link>
      ) : (
        <SupporterText>{badge.customer?.username}</SupporterText>
      )}
      <BadgeSupportedCaption>Supported {utils.getTimeFrom(badge.createdOn)} ago</BadgeSupportedCaption>
    </BadgeText>
    <BadgeImage>
      <Link href={`${routes.badges}/${badge.badgeId}`} passHref>
        <a>
          <Image src={badge.badgeFrontUrl} width={52} height={52} quality={90} unoptimized />
        </a>
      </Link>
    </BadgeImage>
  </BadgeRowWrapper>
);

export default BadgeRow;
