import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@makeship/core';
import { ProductStage, ProductTag } from '../../../../../types/common';
import { getProductFromContext } from '../../../../../context/product';
import { useStore } from '../../../../../store';
import { getQtyOfProductInCart } from '../../../../../utils/cart';
import { getStage, hasProductTag } from '../../../../../utils/product';
import AddToCartStepper from '../../../../AddToCartStepper';
import config from '../../../../../../config.json';
import { useIntersectionObserver } from '../../../../../hooks/useIntersectionObserver';

const { elements } = config;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const AddToCartButton = styled(Button.Primary)<{ uiDisabled: boolean }>`
  width: 100%;
`;

const AddToCartButtonFloating = styled.div<{ shouldDisplay: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'block')};
    flex-direction: ${({ shouldDisplay }) => (shouldDisplay ? 'column' : 'unset')};
    transition: height 500ms ease;
    overflow: hidden;
    height: ${({ shouldDisplay }) => (shouldDisplay ? '76px' : '0px')};
    width: 100%;
    position: fixed;
    bottom: -2px;
    right: 0px;
    z-index: 1;
    border: 2px solid ${({ theme }) => theme.colors.neutral1};
    padding: 0 4px;
    background-color: ${({ theme }) => theme.colors.neutral1};
  }
`;

type AddToCartButtonFloatingContainerProps = {
  number: number;
  setNumber: (value: number) => void;
  totalInventory: number;
  incrementNumber: () => void;
  decrementNumber: () => void;
  handleFloatingAddToCart: () => void;
  shoppingCartRef: React.RefObject<HTMLDivElement>;
  completeTheCollectionRef: React.RefObject<HTMLDivElement>;
};

const AddToCartButtonFloatingContainer: React.FC<AddToCartButtonFloatingContainerProps> = ({
  number,
  setNumber,
  totalInventory,
  incrementNumber,
  decrementNumber,
  handleFloatingAddToCart,
  shoppingCartRef,
  completeTheCollectionRef,
}: AddToCartButtonFloatingContainerProps) => {
  const { state } = useStore();

  const product = getProductFromContext();
  const stage = getStage(product.tags);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const cartProductQty = getQtyOfProductInCart(state?.cart?.checkout, product.title);

  const [isShoppingCartVisible] = useIntersectionObserver(shoppingCartRef, {
    root: null,
    rootMargin: '-64px 0px 0px 0px', // Set top margin to take into account navigation header
    threshold: 0.5,
  });

  const [isCompleteTheCollectionVisible] = useIntersectionObserver(completeTheCollectionRef, {
    root: null,
    rootMargin: '-64px 0px 0px 0px',
    threshold: 0,
  });

  return (
    <AddToCartButtonFloating shouldDisplay={!isShoppingCartVisible && !isCompleteTheCollectionVisible}>
      <AddToCartWrapper>
        <AddToCartStepper
          value={number}
          increase={incrementNumber}
          decrease={decrementNumber}
          setNumber={setNumber}
          uiDisabled={stage === ProductStage.Draft}
          isLimited={isLimited}
          totalInventory={totalInventory}
          cartProductQty={cartProductQty}
        />
        <AddToCartButton
          id={elements.addToCartButton}
          data-testid="add-to-cart-button-floating"
          onClick={() => handleFloatingAddToCart()}
          uiDisabled={stage === ProductStage.Draft || (isLimited && totalInventory < number + cartProductQty)}
          disabled={stage === ProductStage.Draft || (isLimited && totalInventory < number + cartProductQty)}
        >
          Add to Cart
        </AddToCartButton>
      </AddToCartWrapper>
    </AddToCartButtonFloating>
  );
};

export default AddToCartButtonFloatingContainer;
