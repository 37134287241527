import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Button } from '@makeship/core';
import Link from 'next/link';
import { P2, S1, XSRegularStyles } from '../../../Typography';
import {
  getProductIDFromShopifyGID,
  getProductsDisplayed,
  parseMetafields,
  productArrayContainsProductTag,
  renderLink,
  renderRedirectText,
} from '../../../../utils/product';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { reorderProducts } from '../../../../utils/petition';
import { useStore } from '../../../../store';

const RedirectBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
`;

const ImageWrapper = styled.div<{ hasSingleImage?: boolean }>`
  display: flex;
  gap: 18px;
  width: ${({ hasSingleImage }) => !hasSingleImage && '100%'};
  justify-content: center;
  min-width: 94px;
`;

const StyledImage = styled(Image)`
  border-radius: 0px 16px;
  background-color: ${({ theme }) => theme.colors.cloud};
`;

const ContentWrapper = styled.div<{ hasSingleImage?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ hasSingleImage }) => (hasSingleImage ? 'row-reverse' : 'column')};
  align-items: ${({ hasSingleImage }) => (hasSingleImage ? 'center' : 'flex-start')};
  justify-content: ${({ hasSingleImage }) => hasSingleImage && 'flex-end'};
  gap: 20px;
`;

const ShopNowButton = styled(Button.Primary)`
  width: 100%;
  padding: 14px 24px;
`;

const CreatorName = styled(P2)`
  ${XSRegularStyles};
  color: ${({ theme }) => theme.colors.neutral7};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 14px;
  }
  width: 94px;
  text-align: center;
`;

const CampaignCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

type LiveProductRedirectProps = {
  metafields: Metafields;
  productsBySameCreator: Shopify.ProductEdge[];
  productsInSameMakeshiftCollection: Shopify.ProductEdge[];
  product: Shopify.Product;
  topCampaigns: Shopify.ProductEdge[];
  redirectBlockRef: React.RefObject<HTMLDivElement>;
  makeshiftCollectionTitle?: string | null;
};

const LiveProductRedirect: React.FC<LiveProductRedirectProps> = ({
  metafields,
  productsBySameCreator,
  productsInSameMakeshiftCollection,
  product,
  topCampaigns,
  redirectBlockRef,
  makeshiftCollectionTitle,
}: LiveProductRedirectProps) => {
  const { state } = useStore();
  const MAX_PRODUCTS_TO_DISPLAY = 3;
  const productsBySameCreatorToDisplay = reorderProducts(productsBySameCreator).slice(0, MAX_PRODUCTS_TO_DISPLAY);
  const productsInSameMakeshiftCollectionToDisplay = productsInSameMakeshiftCollection.slice(
    0,
    MAX_PRODUCTS_TO_DISPLAY,
  );

  const productsToDisplay = () => {
    if (productsBySameCreatorToDisplay.length > 0) {
      return { products: productsBySameCreatorToDisplay, isDefault: false };
    }
    if (productsInSameMakeshiftCollectionToDisplay.length > 0) {
      return { products: productsInSameMakeshiftCollectionToDisplay, isDefault: false };
    }
    return { products: topCampaigns, isDefault: true };
  };
  const renderProductImages = (productsToDisplay: { products: Shopify.ProductEdge[]; isDefault: boolean }) => {
    if (productsToDisplay.products.length === 1) {
      return productsToDisplay.products.map((product) => (
        <StyledImage
          key={product.node.id}
          src={product.node.images.edges[0].node.transformedSrc}
          alt={product.node.title}
          height={94}
          width={94}
        />
      ));
    }
    if (productsToDisplay.products.length > 0) {
      return productsToDisplay.products.map((productToDisplay, index) => (
        <Link href={`/products/${productToDisplay.node.handle}`} passHref>
          <CampaignCard
            onClick={() =>
              posthog.capture('redirect_product_thumbnail_click', {
                product_title: product.title,
                product_id: getProductIDFromShopifyGID(product.id),
                location: 'past_campaign_redirect',
                is_logged_in: isUserLoggedIn(state.user),
                index,
                productsDisplayed: getProductsDisplayed(
                  productsBySameCreatorToDisplay,
                  productsInSameMakeshiftCollectionToDisplay,
                  topCampaigns,
                ),
                productTags: product.tags,
                relevant_campaign_exists:
                  productsBySameCreator.length > 0 || productsInSameMakeshiftCollection.length > 0,
              })
            }
          >
            <StyledImage
              key={productToDisplay.node.id}
              src={productToDisplay.node.images.edges[0].node.transformedSrc}
              alt={productToDisplay.node.title}
              height={94}
              width={94}
            />
            <CreatorName>
              {productsToDisplay.isDefault
                ? parseMetafields(productToDisplay.node.metafields).creator
                : productToDisplay.node.title}
            </CreatorName>
          </CampaignCard>
        </Link>
      ));
    }
  };

  return (
    <div ref={redirectBlockRef}>
      <RedirectBlockWrapper data-testid="past-campaign-redirect">
        <ContentWrapper
          hasSingleImage={
            productsBySameCreatorToDisplay.length === 1 || productsInSameMakeshiftCollectionToDisplay.length === 1
          }
        >
          <S1>
            {renderRedirectText(
              metafields.creator,
              productsBySameCreatorToDisplay,
              productsInSameMakeshiftCollectionToDisplay,
              makeshiftCollectionTitle,
            )}
          </S1>
          <ImageWrapper
            hasSingleImage={
              productsBySameCreatorToDisplay.length === 1 || productsInSameMakeshiftCollectionToDisplay.length === 1
            }
          >
            {renderProductImages(productsToDisplay())}
          </ImageWrapper>
        </ContentWrapper>
        <Link href={renderLink(productsBySameCreatorToDisplay, productsInSameMakeshiftCollectionToDisplay)} passHref>
          <ShopNowButton
            onClick={() =>
              posthog.capture('pastCampaign_redirect_cta_clicked', {
                productName: product.title,
                productID: getProductIDFromShopifyGID(product.id),
                productsDisplayed: getProductsDisplayed(
                  productsBySameCreatorToDisplay,
                  productsInSameMakeshiftCollectionToDisplay,
                  topCampaigns,
                ),
                is_logged_in: isUserLoggedIn(state.user),
                location: 'past_campaign_redirect',
                redirectDisplaysPetition:
                  productArrayContainsProductTag(productsBySameCreatorToDisplay, 'petition') ||
                  productArrayContainsProductTag(productsInSameMakeshiftCollectionToDisplay, 'petition') ||
                  false,
                relevant_campaign_exists:
                  productsBySameCreator.length > 0 || productsInSameMakeshiftCollection.length > 0,
              })
            }
          >
            Shop Now
          </ShopNowButton>
        </Link>
      </RedirectBlockWrapper>
    </div>
  );
};

export default LiveProductRedirect;
