import React from 'react';
import styled from 'styled-components';
import { P2 } from '../Typography';

const SingleSizeOptionWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 42px;
  height: 42px;

  border-radius: 8px;
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.primaryDark)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.neutral1)};

  &: hover {
    ${({ theme, isSelected }) => !isSelected && `border: 2px solid ${theme.colors.primaryDark}`};
  }
`;

const OptionText = styled(P2)<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.neutral1 : theme.colors.primaryDark)};
  text-align: center;
  text-transform: capitalize;
`;

type SingleSizeOptionProps = {
  variant: Shopify.ProductVariant;
  selected: boolean;
  setSelectedVariant: (e: React.MouseEvent<HTMLDivElement>, variant: Shopify.ProductVariant) => void;
};

const SingleSizeOption: React.FC<SingleSizeOptionProps> = ({
  variant,
  selected,
  setSelectedVariant,
}: SingleSizeOptionProps) => (
  <SingleSizeOptionWrapper isSelected={selected} onClick={(e) => setSelectedVariant(e, variant)}>
    <OptionText isSelected={selected}>{variant.title}</OptionText>
  </SingleSizeOptionWrapper>
);

export default SingleSizeOption;
