import React, { useState } from 'react';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { Link as StyledLink } from '@makeship/core';

import { P2, S2, S2Styles } from '../Typography';
import SingleSizeOption from './SingleSizeOption';
import SizeGuideModal from '../Modal/SizeGuideModal';

const SizeSelectorWrapper = styled.div<{ inCompleteTheCollection?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-bottom: 20px;
  }

  ${({ inCompleteTheCollection }) =>
    inCompleteTheCollection &&
    `margin-top: 8px;
    margin-bottom: 16px;
  `}
`;

const SizesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BottomLink = styled(StyledLink.Primary)`
  ${S2Styles}
  color: ${({ theme }) => theme.colors.primary} !important;
  display: block;
  cursor: pointer;
`;

const ErrorText = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 8px;
  margin-bottom: 20px;
`;

const OptionsWrapper = styled.div<{ error?: string }>`
  width: fit-content;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  column-gap: 14px;
  margin-top: 20px;
  row-gap: 8px;

  ${({ theme, error }) =>
    error &&
    `
    border: 1px solid ${theme.colors.error} !important; 
    padding: 2px;
    border-radius: 8px;
    margin-top: 0;
  `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    column-gap: 4px;
  }

  @media (max-width: 360px) {
    margin-left: -12px;
  }
`;

type SizeSelectorProps = {
  error: string | undefined;
  setError: (error: string | undefined) => void;
  variants: Shopify.ProductVariantEdge[];
  setSelectedVariant: (variant: Shopify.ProductVariant) => void;
  inCompleteTheCollection?: boolean;
  alreadySelectedVariant?: Shopify.ProductVariant | undefined;
};

const SizeSelector: React.FC<SizeSelectorProps> = ({
  error,
  setError,
  variants,
  setSelectedVariant,
  inCompleteTheCollection,
  alreadySelectedVariant,
}: SizeSelectorProps) => {
  const [selectedVariant, setSelectedClothingVariant] = useState<Shopify.ProductVariant | undefined>(
    alreadySelectedVariant,
  );
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);

  const handleSelectClothingVariant = (e: React.MouseEvent<HTMLDivElement>, variant: Shopify.ProductVariant) => {
    setError(undefined);
    setSelectedClothingVariant(variant);
    setSelectedVariant(variant);
    e.stopPropagation();
  };

  const openSizeGuide = () => {
    setIsSizeGuideOpen(true);
  };

  return (
    <SizeSelectorWrapper inCompleteTheCollection={inCompleteTheCollection}>
      <SizesTextWrapper>
        <S2>Select your size:</S2>
        <BottomLink onClick={() => openSizeGuide()}>Size Guide</BottomLink>
      </SizesTextWrapper>
      <ScrollElement name="variants" />
      <ScrollElement name="size-error" />
      {error && <ErrorText>Please select a size!</ErrorText>}
      <OptionsWrapper error={error}>
        {variants.map((variant) => (
          <SingleSizeOption
            variant={variant.node}
            selected={selectedVariant?.id === variant.node.id}
            setSelectedVariant={(e, variant) => handleSelectClothingVariant(e, variant)}
          />
        ))}
      </OptionsWrapper>
      <SizeGuideModal isOpen={isSizeGuideOpen} closeModal={() => setIsSizeGuideOpen(false)} />
    </SizeSelectorWrapper>
  );
};

export default SizeSelector;
