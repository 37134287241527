import React from 'react';
import styled from 'styled-components';
import { Button } from '@makeship/core';
import { ProductStage, ProductTag } from '../../../../../types/common';
import { getProductFromContext } from '../../../../../context/product';
import { useStore } from '../../../../../store';
import { getQtyOfProductInCart } from '../../../../../utils/cart';
import { getStage, hasProductTag } from '../../../../../utils/product';
import AddToCartStepper from '../../../../AddToCartStepper';
import config from '../../../../../../config.json';

const { elements } = config;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const AddToCartButton = styled(Button.Primary)<{ uiDisabled: boolean }>`
  width: 100%;
`;

type AddToCartButtonProps = {
  number: number;
  setNumber: (value: number) => void;
  totalInventory: number;
  incrementNumber: () => void;
  decrementNumber: () => void;
  handleAddToCart: () => void;
  shoppingCartRef: React.RefObject<HTMLDivElement>;
};

const AddToCartButtonContainer: React.FC<AddToCartButtonProps> = ({
  number,
  setNumber,
  totalInventory,
  incrementNumber,
  decrementNumber,
  handleAddToCart,
  shoppingCartRef,
}: AddToCartButtonProps) => {
  const { state } = useStore();

  const product = getProductFromContext();
  const stage = getStage(product.tags);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const cartProductQty = getQtyOfProductInCart(state?.cart?.checkout, product.title);

  return (
    <div ref={shoppingCartRef}>
      <AddToCartWrapper>
        <AddToCartStepper
          value={number}
          increase={incrementNumber}
          decrease={decrementNumber}
          setNumber={setNumber}
          uiDisabled={stage === ProductStage.Draft}
          isLimited={isLimited}
          totalInventory={totalInventory}
          cartProductQty={cartProductQty}
        />

        <AddToCartButton
          id={elements.addToCartButton}
          data-testid="add-to-cart-button"
          onClick={() => handleAddToCart()}
          uiDisabled={stage === ProductStage.Draft || (isLimited && totalInventory < number + cartProductQty)}
          disabled={stage === ProductStage.Draft || (isLimited && totalInventory < number + cartProductQty)}
        >
          Add to Cart
        </AddToCartButton>
      </AddToCartWrapper>
    </div>
  );
};

export default AddToCartButtonContainer;
