import React, { useContext, useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import VariantSelector from '../VariantSelector';
import AddToCartButtonContainer from './AddToCartButtonContainer';
import AddToCartButtonFloatingContainer from './AddToCartButtonFloatingContainer';
import { addProduct, showCart } from '../../../../store/store.actions';
import { CartEventLocations, ProductTag, ProductType, ProductStage } from '../../../../types/common';
import {
  analyticsTrackAddToCart,
  analyticsTrackViewCart,
  sendAddUnselectedVariantToCartEvent,
} from '../../../../utils/analytics';
import { getQtyOfProductInCart } from '../../../../utils/cart';
import { getProductType, getStage, hasProductTag, parseMetafields } from '../../../../utils/product';
import { useStore } from '../../../../store';
import SpamFlaggingContext from '../../../../context/spamFlagging';
import SizeSelector from '../../../SizeSelector';

type ProductDetailsProps = {
  selectedVariant: Shopify.ProductVariant | undefined;
  setSelectedVariant: (variant: Shopify.ProductVariant) => void;
  completeTheCollectionRef: React.RefObject<HTMLDivElement>;
};

const AddToCartContainer: React.FC<ProductDetailsProps> = ({
  selectedVariant,
  setSelectedVariant,
  completeTheCollectionRef,
}: ProductDetailsProps) => {
  const { state, dispatch } = useStore();
  const { incrementSpamCounter, isSpam } = useContext(SpamFlaggingContext);
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { totalInventory } = productDetails;
  const stage = getStage(product.tags);
  const metafields = parseMetafields(product.metafields);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const cartProductQty = getQtyOfProductInCart(state?.cart?.checkout, product.title);
  const hasManyVariants = product.variants.edges.length > 1;
  const productType = getProductType(product.productType);

  const [number, setNumber] = useState(1);
  const [error, setError] = useState<string>();

  const shoppingCartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNumber(1);
  }, [product]);

  const incrementNumber = () => {
    if (isLimited) {
      if (totalInventory - (number + cartProductQty) <= 0) return;
    }
    setNumber((number || 0) + 1);
  };

  const decrementNumber = () => {
    if (number <= 1) {
      return;
    }
    if ((number || 0) > 1) {
      setNumber(number - 1);
    }
  };

  const handleFloatingAddToCart = () => {
    if (!selectedVariant) {
      scroller.scrollTo('variants', {
        duration: 800,
        smooth: 'easeInOutQuad',
        offset: -300,
      });
    }
    handleAddToCart(CartEventLocations.StickyCartButton);
  };

  const handleAddToCart = (eventLocation?: CartEventLocations) => {
    if (selectedVariant) {
      addProduct(state.cart, product, number || 0, selectedVariant).then((action) => dispatch(action));
      dispatch(showCart());
      incrementSpamCounter();
      if (!isSpam) {
        analyticsTrackAddToCart(
          product,
          number || 0,
          state.user,
          eventLocation || CartEventLocations.ProductPage,
          selectedVariant,
          metafields.campaignID,
        );
        analyticsTrackViewCart(
          state.cart.checkout?.lineItems.edges,
          state.cart.checkout?.totalPriceV2.amount,
          state.user,
          CartEventLocations.CartPopout,
        );
      }

      if (isLimited) {
        if (totalInventory - (number + cartProductQty) <= 0) setNumber(1);
        if (totalInventory - (2 * number + cartProductQty) <= 0) {
          const newInputNum = totalInventory - (number + cartProductQty) || 1;
          setNumber(newInputNum);
        }
      }
      return;
    }

    sendAddUnselectedVariantToCartEvent(product.title);
    setError('Please select an option ');
  };

  return (
    <>
      {hasManyVariants && productType !== ProductType.Hoodie && (
        <VariantSelector error={error} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
      )}
      {productType === ProductType.Hoodie &&
        ((stage === ProductStage.Live && product.availableForSale) || stage === ProductStage.Draft) && (
          <SizeSelector
            error={error}
            setError={setError}
            variants={product.variants.edges}
            setSelectedVariant={setSelectedVariant}
          />
        )}
      <AddToCartButtonFloatingContainer
        number={number}
        setNumber={setNumber}
        totalInventory={totalInventory}
        incrementNumber={incrementNumber}
        decrementNumber={decrementNumber}
        handleFloatingAddToCart={handleFloatingAddToCart}
        shoppingCartRef={shoppingCartRef}
        completeTheCollectionRef={completeTheCollectionRef}
      />
      <AddToCartButtonContainer
        number={number}
        setNumber={setNumber}
        totalInventory={totalInventory}
        incrementNumber={incrementNumber}
        decrementNumber={decrementNumber}
        handleAddToCart={handleAddToCart}
        shoppingCartRef={shoppingCartRef}
      />
    </>
  );
};

export default AddToCartContainer;
