import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { S1, S2 } from '../Typography';

const CartQuantityWrapper = styled.div<{ border: boolean }>`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: ${({ border }) => (border ? 'solid' : 'none')};
  border-width: 1px;
  border-radius: 30px;
  border-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  padding: 12px 8px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral1};
`;

const CartQuantityButton = styled.button<{ uiDisabled: boolean; disablePlusButton: boolean | undefined }>`
  padding: 10px 4px;
  width: 20px;
  height: 8px;
  background-color: ${({ uiDisabled, theme, disablePlusButton }) =>
    uiDisabled
      ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)
      : disablePlusButton
      ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)
      : theme.colors.primary};
  color: ${({ theme }) => theme.colors.neutral1};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  ${S2} {
    color: ${({ theme }) => theme.colors.neutral1};
    padding: 8px 16px;
  }
  &:active,
  :focus,
  :focus-visible {
    outline: none;
  }
`;

const QuantityText = styled(S1)`
  width: 100%;
  padding: 0px 4px;
  text-align: center;
`;

const TextInput = styled.input`
  width: 100%;
  border: none;
  text-align: center;
  font-weight: bold;
`;
type AddToCartStepperProps = {
  value: number;
  increase: () => void;
  decrease: () => void;
  setNumber: (number: number) => void;
  uiDisabled: boolean;
  isLimited?: boolean;
  totalInventory?: number;
  cartProductQty: number;
  border?: boolean | true;
};

const AddToCartStepper: React.FC<AddToCartStepperProps> = ({
  value,
  increase,
  decrease,
  setNumber,
  uiDisabled,
  isLimited,
  totalInventory,
  cartProductQty,
  border = true,
}: AddToCartStepperProps) => {
  const inventory = totalInventory || 0;
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(e.currentTarget.value, 10) || 1;

    if (isLimited && inventory - (num + cartProductQty) <= 0) {
      setNumber(inventory - cartProductQty);
    } else {
      num <= 1 ? setNumber(1) : setNumber(parseInt(e.currentTarget.value, 10));
    }
  };

  return (
    <CartQuantityWrapper border={border}>
      <CartQuantityButton onClick={decrease} uiDisabled={uiDisabled} disablePlusButton={false}>
        <S2>-</S2>
      </CartQuantityButton>
      <QuantityText>
        <TextInput
          type="text"
          inputMode="numeric"
          value={value}
          pattern="^[1-9]\d*$"
          onChange={(e) => handleInput(e)}
        />
      </QuantityText>
      <CartQuantityButton
        onClick={increase}
        uiDisabled={uiDisabled}
        disablePlusButton={isLimited && inventory - (value + cartProductQty) <= 0}
      >
        <S2>+</S2>
      </CartQuantityButton>
    </CartQuantityWrapper>
  );
};
export default AddToCartStepper;
