import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';

import Modal from '..';
import { H5, P2, Caption, Strong } from '../../Typography';
import { modalTheme } from '../../../styles/themes';

const ModalContent = styled.div`
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ModalCaption = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 32px;
  top: 26px;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  text-align: left;
  margin: 16px 0;
`;

const SizeGuideBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`;

const SizeChartDiagram = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const Picture = styled.picture`
  width: 100%;
  height: auto;
  display: block;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 820px) {
    img {
      width: auto;
      height: 100%;
      max-height: 426px;
    }
  }

  @media (max-width: 368px) {
    img {
      width: 100%;
      height: auto;
      max-height: auto;
    }
  }
`;

const SizeGuideModalTheme = {
  ...modalTheme,
  content: {
    ...modalTheme.content,
    borderRadius: '30px',
    width: '800px',
  },
};

type SizeGuideModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const SizeGuideModal: React.FC<SizeGuideModalProps> = ({ isOpen, closeModal }: SizeGuideModalProps) => {
  const smallMobileBreakpoint = '426';
  const mobileBreakpoint = '820';

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} theme={SizeGuideModalTheme}>
      <ModalContent>
        <CancelIcon onClick={closeModal}>
          <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
        </CancelIcon>
        <ModalHeader>
          <H5>Hoodies Size Guide</H5>
        </ModalHeader>
        <SizeGuideBody>
          <SizeChartDiagram>
            <Picture>
              <source
                media={`(max-width: ${smallMobileBreakpoint}px)`}
                srcSet="/assets/hoodies/size_chart_small_mobile.png"
              />
              <source media={`(max-width: ${mobileBreakpoint}px)`} srcSet="/assets/hoodies/size_chart_mobile.png" />
              <source media={`(min-width: ${mobileBreakpoint}px)`} srcSet="/assets/hoodies/size_chart_desktop.png" />
              <img src="/assets/hoodies/size_chart_small_mobile.png" alt="Size Chart" />
            </Picture>
          </SizeChartDiagram>
          <div>
            <ModalCaption>Finding Your Correct Size</ModalCaption>
            <P2>
              For <Strong>length</Strong>, measure from the top of your shoulder to your waist. For{' '}
              <Strong>width</Strong>, measure around your chest under each arm. And for <Strong>sleeve</Strong>, measure
              from the edge of your shoulder to your wrist.
            </P2>
          </div>
        </SizeGuideBody>
      </ModalContent>
    </Modal>
  );
};

export default SizeGuideModal;
